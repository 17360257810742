import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { MyOrdersDetail } from "../../Components/MyOrdersDetail/MyOrdersDetail";
import { OrderAddress } from "../../Components/OrderAddress/OrderAddress";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import { useApp } from "../../context/AppContextProvider";
import ApiService from "../../services/ApiService";
import { MyAccountMenu } from "../MyAccount/MyAccount";
import SeoDefault from "../../Components/Seo/SeoDefault";
import { enviroment } from "../../enviroment";

export const OrderDetails = () => {
  const location = useLocation();
  const [orderDetail, setOrderDetail] = useState("");
  const appData = useApp();
  let windowWidth = appData.appData.windowWidth;

  useEffect(() => {
    let orderDetail = location.state.payload;
    ApiService.getOrderDetail(orderDetail)
      .then((res) => {
        setOrderDetail(res.payload_orderDetails);
      })
      .catch((err) => { });
  }, []);
  return (
    <React.Fragment>
      <SeoDefault
        title={`Order Details - ${enviroment.BUSINESS_NAME}`}
        description={`View details of your ${enviroment.BUSINESS_NAME} order and track its status.`}
        keywords={`${enviroment.BUSINESS_NAME} order details, track order, purchase history, Chaina Ram Sweets, Kandavika Sweets, Best Indian Sweets Online, Desi Ghee Sweets, Buy Sevpak Online, Ghevar Online Delivery, Gulab Jamun Online, Authentic Indian Sweets, Traditional Indian Sweets, Chaina Ram Sweets in Delhi, Chaina Ram GK 2, Chaina Ram East of Kailash, Buy Sweets in Delhi, Official Chaina Ram Franchise, Order Sweets Online Delhi, Best Mithai Shop in Delhi, Indian Sweets Home Delivery`}
        canonicalUrl={`${process.env.REACT_APP_URL}/order-details`}
        pageType="website"
        robots="noindex"
        breadcrumbItems={[
          { name: "Home", url: `${process.env.REACT_APP_URL}/` },
          { name: "My Orders", url: `${process.env.REACT_APP_URL}/my-orders` },
          { name: "Order Details", url: `${process.env.REACT_APP_URL}/order-details` },
        ]}
      />
      {windowWidth === "mobile" ? (
        <React.Fragment>
          <PageHeader title="Order Detail" />
          {orderDetail !== "" && (
            <React.Fragment>
              <OrderAddress orderDetail={orderDetail} />
              <MyOrdersDetail orderDetail={orderDetail} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header />
          <div className="col-12 d-inline-flex mt-4">
            <div className="container">
              <div className="d-flex gap-3 col-12 align-items-start">
                <MyAccountMenu />
                <div className="d-flex flex-column flex-grow-1">
                  {orderDetail !== "" && (
                    <React.Fragment>
                      <OrderAddress orderDetail={orderDetail} />
                      <MyOrdersDetail orderDetail={orderDetail} />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { StarFill } from 'react-bootstrap-icons';
import OwlCarousel from 'react-owl-carousel';
import styles from './Review.module.css';

const testimonials = [
    {
        id: 1,
        name: 'Rahul Batra',
        content: 'One of the best sweet shops in India.',
        rating: 5,
    },
    {
        id: 2,
        name: 'manthan kumar',
        content: 'Chain Ram sweets are good in taste. All sweet made with Desi ghee. a must visit shop if you love sweets',
        rating: 5,
    },
    {
        id: 3,
        name: 'Raj Vimal',
        content: 'Delicious breakfast honestly! You could try chhole puri, Keshar ghevar, and Karachi halwa their speciality',
        rating: 5,
    },
    {
        id: 4,
        name: 'SJ',
        content: 'Best karachi halwa and sohan halwa 👍👍been a fan of these sweets for more than 4 decades. Would recommend this shop',
        rating: 5,
    },
];

export default function Reviews() {
    const carouselRef = useRef(null); // Ref for the carousel

    const options = {
        items: 1,
        loop: true,
        nav: false, // Disable default navigation
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };

    return (
        <section className={styles.customerExperience}>
            <Container>
                <Row className="justify-content-center text-center">
                    <Col md={10} lg={8} className='position-relative'>
                        <h2 className={`${styles.title} textSpecial`}>
                            <span className={styles.titleDark} style={{
                                textDecoration: 'underline',
                            }}>We Value</span>{' '}
                            <span className={styles.titleLight}>your feedback</span>
                        </h2>
                        <OwlCarousel ref={carouselRef} className="owl-theme" {...options}>
                            {testimonials.map((testimonial) => (
                                <div key={testimonial.id} className={styles.testimonialItem}>
                                    <div className={styles.testimonialContent}>
                                        <p className='fs-4'>{testimonial.content}</p>
                                        <div style={{
                                            textAlign: 'center',
                                        }}>
                                            <h3 style={{
                                                fontSize: '16px'
                                            }}>{testimonial.name}</h3>
                                            <div className={styles.rating}>
                                                {[...Array(testimonial.rating)].map((_, index) => (
                                                    <StarFill key={index} className={styles.star} size={20} fill="#FFA500" color="#FFA500" />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                        {/* Custom Navigation Buttons */}
                        <div className={styles.customNav}>
                            <button className={`${styles.navButton} ${styles.prev}`} onClick={() => carouselRef.current.prev()}>
                                ‹
                            </button>
                            <button className={`${styles.navButton} ${styles.next}`} onClick={() => carouselRef.current.next()}>
                                ›
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
import React from "react";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { MyOrdersBox } from "../../Components/MyOrdersBox/MyOrdersBox";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import { useApp } from "../../context/AppContextProvider";
import { MyAccountMenu } from "../MyAccount/MyAccount";
import SeoDefault from "../../Components/Seo/SeoDefault";
import { enviroment } from "../../enviroment";

export const MyOrders = () => {
  const appData = useApp();
  const windowWidth = appData.appData.windowWidth;
  return (
    <React.Fragment>
      <SeoDefault
        title={`My Orders - ${enviroment.BUSINESS_NAME}`}
        description={`View your order history and track purchases at ${enviroment.BUSINESS_NAME}.`}
        keywords={`${enviroment.BUSINESS_NAME} orders, order history, track orders, Chaina Ram Sweets, Kandavika Sweets, Best Indian Sweets Online, Desi Ghee Sweets, Buy Sevpak Online, Ghevar Online Delivery, Gulab Jamun Online, Authentic Indian Sweets, Traditional Indian Sweets, Chaina Ram Sweets in Delhi, Chaina Ram GK 2, Chaina Ram East of Kailash, Buy Sweets in Delhi, Official Chaina Ram Franchise, Order Sweets Online Delhi, Best Mithai Shop in Delhi, Indian Sweets Home Delivery`}
        canonicalUrl={`${process.env.REACT_APP_URL}/my-orders`}
        pageType="website"
        robots="noindex"
        breadcrumbItems={[
          { name: "Home", url: `${process.env.REACT_APP_URL}/` },
          { name: "My Orders", url: `${process.env.REACT_APP_URL}/my-orders` },
        ]}
      />
      {windowWidth === "mobile" ? (
        <React.Fragment>
          <PageHeader title="My Orders" />
          <MyOrdersBox />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header />
          <div className="col-12 d-inline-flex mt-4">
            <div className="container">
              <div className="d-flex gap-3 col-12 align-items-start">
                <MyAccountMenu />
                <div className="d-flex flex-grow-1">
                  <MyOrdersBox />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

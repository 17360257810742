import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import {
  LocationIcon,
  LogoutIcon,
  OrderIcon,
  UserIcon,
} from "../../Components/siteIcons";
import { useApp } from "../../context/AppContextProvider";
import { enviroment } from "../../enviroment";
import { AppNotification } from "../../utils/helper";
import styles from "./MyAccount.module.css";
import SeoDefault from "../../Components/Seo/SeoDefault";

const userMenu = [
  {
    name: "Profile Details",
    path: "/my-account",
    icon: <UserIcon color="#454545" />,
  },
  {
    name: "Orders",
    path: "/my-orders",
    icon: <OrderIcon color="#454545" />,
  },
  {
    name: "Address",
    path: "/my-address",
    icon: <LocationIcon color="#454545" />,
  },
  {
    name: "Log Out",
    icon: <LogoutIcon color="#454545" />,
  },
];

export const MyAccountMenu = () => {
  const appData = useApp();
  const navigate = useNavigate();
  const location = useLocation();

  const logdOut = async () => {
    await appData.setAppData({ ...appData.appData, user: "", loggedIn: false });
    localStorage.removeItem("user");
    localStorage.removeItem("loggedIn");
    AppNotification(
      "Logged Out",
      "You have been successfully logged out.",
      "success"
    );
    navigate("/");
  };

  const isActive = (item) => item === location.pathname;

  return (
    <div
      className={`${styles.loginBox} d-inline-flex flex-column gap-2 py-4 w-25`}
    >
      <h5 className="px-4">My Account</h5>
      {/* user list */}
      <ul className="m-0 p-0">
        {userMenu.map((item, idx) => {
          return (
            <div
              className={`${styles.accountRow} ${
                isActive(item.path) ? styles.activeMenu : ""
              } col-12 d-inline-flex align-items-center gap-2 px-4`}
              onClick={() => (item.path ? navigate(item.path) : logdOut)}
              role="button"
              key={idx}
            >
              <span
                className={`${styles.accountIcon} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
              >
                {item.icon}
              </span>
              <h6 className={`${styles.accountLabel} d-inline-flex m-0`}>
                {item.name}
              </h6>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export const MyAccount = () => {
  const appData = useApp();
  let windowWidth = appData.appData.windowWidth;
  const userInfo = appData?.appData?.user;
  const navigate = useNavigate();

  const userLoggedOut = () => {
    let emptyCartData = [];
    appData.setAppData({
      ...appData.appData,
      user: "",
      loggedIn: false,
      cartSaved: false,
      cartData: emptyCartData,
      cartCount: 0,
    });
    localStorage.setItem("cartData", JSON.stringify(emptyCartData));
    localStorage.removeItem("user");
    localStorage.removeItem("cartSaved");
    localStorage.removeItem("loggedIn");
    AppNotification(
      "Logged Out",
      "You have been successfully logged out.",
      "success"
    );
    navigate("/");
  };

  useEffect(() => {
    document.title = enviroment.BUSINESS_NAME + " - My Account";
  }, []);
  return (
    <React.Fragment>
      <SeoDefault
        title={`My Account - ${enviroment.BUSINESS_NAME}`}
        description={`Manage your ${enviroment.BUSINESS_NAME} account, view orders, and update your profile.`}
        keywords={`${enviroment.BUSINESS_NAME} account, my profile, customer dashboard, Chaina Ram Sweets, Kandavika Sweets, Best Indian Sweets Online, Desi Ghee Sweets, Buy Sevpak Online, Ghevar Online Delivery, Gulab Jamun Online, Authentic Indian Sweets, Traditional Indian Sweets, Chaina Ram Sweets in Delhi, Chaina Ram GK 2, Chaina Ram East of Kailash, Buy Sweets in Delhi, Official Chaina Ram Franchise, Order Sweets Online Delhi, Best Mithai Shop in Delhi, Indian Sweets Home Delivery`}
        canonicalUrl={`${process.env.REACT_APP_URL}/my-account`}
        pageType="website"
        robots="noindex"
        breadcrumbItems={[
          { name: "Home", url: `${process.env.REACT_APP_URL}/` },
          { name: "My Account", url: `${process.env.REACT_APP_URL}/my-account` },
        ]}
      />
      {windowWidth === "mobile" ? (
        <React.Fragment>
          <PageHeader title="My Account" />
          <div className="d-inline-flex col-12 p-3 flex-column gap-3">
            <div
              className={`${styles.loginBox} d-inline-flex align-items-center col-12 gap-2 p-3`}
            >
              <div
                className={`${styles.loginImage} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
              >
                <UserIcon color="#000" />
              </div>
              <div className="d-inline-flex flex-column flex-grow-1">
                {userInfo?.name && (
                  <h5
                    className={`${styles.profileName} col-12 d-inline-block mb-0`}
                  >
                    {userInfo?.name}
                  </h5>
                )}
                <span
                  className={`${styles.profileNumber} col-12 d-inline-block`}
                >
                  +91- {userInfo?.contact}
                </span>
                <span
                  className={`${styles.profileEmail} col-12 d-inline-block`}
                ></span>
              </div>
            </div>
            <div
              className={`${styles.loginBox} d-inline-flex flex-column col-12 gap-2 py-4 p-3`}
            >
              <div
                className={`${styles.accountRow} col-12 d-inline-flex align-items-center gap-2`}
                onClick={() => navigate("/my-orders")}
              >
                <span
                  className={`${styles.accountIcon} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
                >
                  <OrderIcon color="#454545" />
                </span>
                <h6 className={`${styles.accountLabel} d-inline-flex m-0`}>
                  My Orders
                </h6>
              </div>
              <div
                className={`${styles.accountRow} col-12 d-inline-flex align-items-center gap-2`}
                onClick={() => navigate("/my-address")}
              >
                <span
                  className={`${styles.accountIcon} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
                >
                  <LocationIcon color="#454545" />
                </span>
                <h6 className={`${styles.accountLabel} d-inline-flex m-0`}>
                  Delivery Address
                </h6>
              </div>
              <div
                className={`${styles.accountRow} ${styles.LoggedOutRow} col-12 d-inline-flex align-items-center gap-2`}
                role="button"
                onClick={() => userLoggedOut()}
              >
                <span
                  className={`${styles.accountIcon} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
                >
                  <LogoutIcon color="#454545" />
                </span>
                <h6 className={`${styles.accountLabel} d-inline-flex m-0`}>
                  Log Out
                </h6>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header />
          <div className="container">
            <div className="d-flex gap-3 mt-4">
              <MyAccountMenu />
              <div className="w-full flex-grow-1">
                <div
                  className={`${styles.loginBox} d-inline-flex align-items-center gap-2 p-3`}
                >
                  <div
                    className={`${styles.loginImage} d-inline-flex flex-shrink-0 align-items-center justify-content-center`}
                  >
                    <UserIcon color="#000" />
                  </div>
                  <div className="d-inline-flex flex-column flex-grow-1">
                    {userInfo?.name && (
                      <h5
                        className={`${styles.profileName} col-12 d-inline-block mb-0`}
                      >
                        {userInfo?.name}
                      </h5>
                    )}
                    <span
                      className={`${styles.profileNumber} col-12 d-inline-block`}
                    >
                      +91- {userInfo?.contact}
                    </span>
                    <span
                      className={`${styles.profileEmail} col-12 d-inline-block`}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <Footer />
    </React.Fragment>
  );
};

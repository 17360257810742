
import React from "react";
import { Helmet } from "react-helmet-async";
import { JsonLd } from "react-schemaorg";
import { enviroment } from "../../enviroment";

export default function SeoDefault({
    title = "Buy Chaina Ram Sweets & Namkeen Online | Kandavika - Official Chaina Ram Franchisee",
    description = "Explore a wide range of authentic Indian sweets from Kandavika, the official franchisee of Chaina Ram Sindhi Halwai. Made with pure Desi Ghee, our delicacies like Sevpak, Ghevar, and Gulab Jamun are available at our GK 2 & East of Kailash outlets or for delivery across Delhi.",
    keywords = "Chaina Ram Sweets, Buy Chaina Ram Sweets Online, Chaina Ram Sindhi Halwai, Kandavika Sweets, Best Indian Sweets Online, Desi Ghee Sweets, Buy Sevpak Online, Ghevar Online Delivery, Gulab Jamun Online, Authentic Indian Sweets, Traditional Indian Sweets, Chaina Ram Sweets in Delhi, Chaina Ram GK 2, Chaina Ram East of Kailash, Buy Sweets in Delhi, Official Chaina Ram Franchise, Order Sweets Online Delhi, Best Mithai Shop in Delhi, Indian Sweets Home Delivery",
    canonicalUrl = `${process.env.REACT_APP_URL}`,
    image = `${process.env.REACT_APP_URL}/logo.png`,
    pageType = "website",
    productData = null,
    breadcrumbItems = [{ name: "Home", url: `${process.env.REACT_APP_URL}/` }],
}) {

    function removeHtmlAndTruncate(text, maxLength = 200) {
        // Remove HTML tags using DOM parsing (safer than regex)
        const tempElement = document.createElement("div");
        tempElement.innerHTML = text || "";
        const cleanText = tempElement.textContent || tempElement.innerText || "";
        // Truncate the text if it exceeds the limit
        return cleanText.length > maxLength
            ? `${cleanText.substring(0, maxLength).trim()}...`
            : cleanText;
    }


    const cleanAndTruncate = (text, maxLength) => {
        if (!text) return "No description available";
        const tempElement = document.createElement("div");
        tempElement.innerHTML = text;
        const cleanText = tempElement.textContent || tempElement.innerText || "";
        return cleanText.length > maxLength
            ? `${cleanText.substring(0, maxLength).trim()}...`
            : cleanText;
    };

    const seoTitle = cleanAndTruncate(title, 60);
    const seoDescription = cleanAndTruncate(description, 160);
    const seoImage = image || `${process.env.REACT_APP_URL}/default-image.jpg`;
    const storeName = enviroment.BUSINESS_NAME;

    return (
        <>
            <Helmet>
                {/* Core Meta Tags */}
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#CF102E" />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content={keywords} />
                <link rel="canonical" href={canonicalUrl} />

                {/* Open Graph Tags */}
                <meta property="og:title" content={cleanAndTruncate(title, 70)} />
                <meta property="og:description" content={cleanAndTruncate(description, 200)} />
                <meta property="og:image" content={seoImage} />
                <meta property="og:image:secure_url" content={seoImage} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:alt" content={`${title} - ${storeName}`} />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:type" content={pageType} />
                <meta property="og:site_name" content={storeName} />
                <meta property="og:locale" content="en_IN" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={cleanAndTruncate(title, 70)} />
                <meta name="twitter:description" content={cleanAndTruncate(description, 200)} />
                <meta name="twitter:image" content={seoImage} />
                <meta name="twitter:image:alt" content={`${title} - ${storeName}`} />
            </Helmet>

            {/* Breadcrumb Schema */}
            <JsonLd
                item={{
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    itemListElement: breadcrumbItems.map((item, index) => ({
                        "@type": "ListItem",
                        position: index + 1,
                        name: item.name,
                        item: item.url,
                    })),
                }}
            />

            {/* Product Schema (if productData is provided) */}
            {productData && (
                <>
                    {/* Featured Products Carousel */}
                    {productData?.featured?.length > 0 && (
                        <JsonLd
                            item={{
                                "@context": "https://schema.org",
                                "@type": "ItemList",
                                name: "Featured Products",
                                itemListElement: productData.featured.map((featuredProduct, index) => ({
                                    "@type": "ListItem",
                                    position: index + 1,
                                    item: {
                                        "@type": "Product",
                                        name: featuredProduct?.name || "Unnamed Product",
                                        image: featuredProduct?.image || "",
                                        url: `${process.env.REACT_APP_URL}/product/${featuredProduct?.name_url || ""}`,
                                        sku: featuredProduct?.product_id?.toString() || "",
                                        offers: {
                                            "@type": "Offer",
                                            price: Number.parseFloat(featuredProduct?.selling_price) || 0,
                                            priceCurrency: "INR",
                                            availability: featuredProduct?.stock > 0
                                                ? "https://schema.org/InStock"
                                                : "https://schema.org/OutOfStock",
                                        },
                                    },
                                })),
                            }}
                        />
                    )}

                    {/* Similar Products Carousel */}
                    {productData?.similar?.length > 0 && (
                        <JsonLd
                            item={{
                                "@context": "https://schema.org",
                                "@type": "ItemList",
                                name: "Similar Products",
                                itemListElement: productData.similar.map((similarProduct, index) => ({
                                    "@type": "ListItem",
                                    position: index + 1,
                                    item: {
                                        "@type": "Product",
                                        name: similarProduct?.name || "Unnamed Product",
                                        image: similarProduct?.image || "",
                                        url: `${process.env.REACT_APP_URL}/product/${similarProduct?.name_url || ""}`,
                                        sku: similarProduct?.product_id?.toString() || "",
                                        offers: {
                                            "@type": "Offer",
                                            price: Number.parseFloat(similarProduct?.selling_price) || 0,
                                            priceCurrency: "INR",
                                            availability: similarProduct?.stock > 0
                                                ? "https://schema.org/InStock"
                                                : "https://schema.org/OutOfStock",
                                        },
                                    },
                                })),
                            }}
                        />
                    )}

                    {/* Main Product Schema */}
                    <JsonLd
                        item={{
                            "@context": "https://schema.org",
                            "@type": "Product",
                            name: productData?.name || "Unnamed Product",
                            image: [
                                productData?.image || "",
                                ...(productData?.gallery_images?.map(
                                    (img) => `https://rewardsplus.in/uploads/app/public/company/product/${img}`
                                ) || []),
                            ].filter(Boolean),
                            description: removeHtmlAndTruncate(productData?.description),
                            sku: productData?.product_id?.toString() || "",
                            brand: {
                                "@type": "Brand",
                                name: productData?.brand_name || "N/A",
                            },
                            category: productData?.category_name || "N/A",
                            offers: [
                                {
                                    "@type": "Offer",
                                    url: window.location.href,
                                    priceSpecification: [
                                        {
                                            "@type": "UnitPriceSpecification",
                                            priceType: "https://schema.org/ListPrice",
                                            price: Number.parseFloat(productData?.mrp) || 0,
                                            priceCurrency: "INR",
                                        },
                                        {
                                            "@type": "UnitPriceSpecification",
                                            priceType: "https://schema.org/SalePrice",
                                            price: Number.parseFloat(productData?.selling_price) || 0,
                                            priceCurrency: "INR",
                                        },
                                    ],
                                    priceCurrency: "INR",
                                    price: Number.parseFloat(productData?.selling_price) || 0,
                                    itemCondition: "https://schema.org/NewCondition",
                                    availability: productData?.stock > 0
                                        ? "https://schema.org/InStock"
                                        : "https://schema.org/OutOfStock",
                                    seller: {
                                        "@type": "Organization",
                                        name: productData?.store_name || "Kandavika",
                                    },
                                    shippingDetails: {
                                        "@type": "OfferShippingDetails",
                                        description:
                                            "Shipping costs vary based on location within India. Check store policy for details.",
                                        shippingSettingsLink: `${process.env.REACT_APP_URL}/shipping-information-policy`,
                                        shippingDestination: {
                                            "@type": "DefinedRegion",
                                            addressCountry: "IN",
                                        },
                                    }
                                },
                                ...(productData?.bank_offer?.map((bankOffer) => ({
                                    "@type": "Offer",
                                    name: bankOffer?.description || "Bank Offer",
                                    price: Number.parseFloat(productData?.selling_price) || 0,
                                    priceCurrency: "INR",
                                    availability: productData?.stock > 0
                                        ? "https://schema.org/InStock"
                                        : "https://schema.org/OutOfStock",
                                    validFrom: bankOffer?.starttime || "",
                                    validThrough: bankOffer?.endtime || "",
                                    url: window.location.href,
                                    seller: {
                                        "@type": "Organization",
                                        name: productData?.store_name || "Kandavika",
                                    },
                                })) || []),
                            ],
                            review: productData?.ratings?.map((rating) => ({
                                "@type": "Review",
                                reviewRating: {
                                    "@type": "Rating",
                                    ratingValue: rating?.rating?.toString() || "5",
                                    bestRating: "5",
                                },
                                name: rating?.review || "Great Product",
                                author: {
                                    "@type": "Person",
                                    name: `Customer ${rating?.customer_id || "N/A"}`,
                                },
                                datePublished: rating?.created_at?.split(" ")[0] || "",
                            })) || [],
                            aggregateRating: productData?.total_rating > 0
                                ? {
                                    "@type": "AggregateRating",
                                    ratingValue: productData?.total_rating || "5.0",
                                    reviewCount: productData?.ratings?.length || 1,
                                    bestRating: "5",
                                    worstRating: "1",
                                }
                                : undefined,
                            url: `${process.env.REACT_APP_URL}/product/${productData?.name_url || ""}`,
                        }}
                    />
                </>
            )}
        </>
    );
};

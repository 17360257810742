import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import styles from "./LookingFor.module.css";

import { useAppStore } from "../../store";
import { LookingForBannerLoader } from "../Loader/Loader";

export const LookingFor = () => {
  const categories = useAppStore((state) => state.categories);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const subCatProduts = (categorySlug, verticalSlug) => {
    navigate(`/store-product/vertical/${verticalSlug}/category/${categorySlug}`);
  };

  useEffect(() => {
    if (categories.length > 0) {
      setLoading(false);
    }
  }, [categories]);

  const responsiveItems =
    window.innerWidth >= 1300
      ? 6
      : window.innerWidth >= 1200
        ? 5
        : window.innerWidth >= 992
          ? 4
          : window.innerWidth >= 768
            ? 3
            : 2.5;

  return (
    <React.Fragment>
      {loading ? (
        <LookingForBannerLoader />
      ) : (
        <div className={`${styles.shopAgeBox} py-5 px-3 col-12 d-inline-flex mb-3`}>
          <div className="container d-flex flex-column m-auto">
            <h2 style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              gap: "20px",
            }}>
              <span className="textSpecial" style={{
                textDecoration: 'none',
                color: '#C02F1D',
                textShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
                letterSpacing: '0.5px',
                fontWeight: 'normal',
                fontSize: '48px',
              }}>Mithaas</span>
              <img
                src="/images/chana-ram.png"
                alt="Chana Ram"
                style={{
                  maxWidth: "190px",
                }}
              />
            </h2>
            <div className="col-12 d-inline-flex">
              <ReactOwlCarousel
                className="carousel-looking-for col-12 brandSilder owl-theme"
                margin={10}
                loop={false}
                dots={false}
                items={responsiveItems}
                nav={true}
              >
                {categories?.map((item, idx) => {
                  return (
                    <div key={item?.id}>
                      <div
                        className={`${styles.thumbItem} `}
                        onClick={() =>
                          subCatProduts(
                            item?.category?.name_url,
                            item?.verticalSlug
                          )
                        }
                        onKeyDown={() =>
                          subCatProduts(
                            item?.category?.name_url,
                            item?.verticalSlug
                          )
                        }
                        style={{
                          background: item?.category?.color_code
                        }}
                      >
                        <img src="/images/ring.png" alt="Chana Ram" style={{
                          position: "absolute",
                          top: "-25px",
                          left: "-25px",
                          minWidth: "250px",
                          minHeight: "250px",
                        }} />
                        <div className={`${styles.lookingForContainer} d-flex justify-content-center align-items-center`}>
                          <img
                            src={item?.category?.image}
                            alt={item?.category?.name}
                            className="object-fit-fill categoryImg col-12 d-inline-block"
                          />
                        </div>
                      </div>
                      <p
                        className={`${styles.thumbName} text-truncate col-12 text-center mb-0`}
                      >
                        {item?.category?.name}
                      </p>
                    </div>
                  );
                })}
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

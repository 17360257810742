import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './App.css';
import { AppProvider } from "./context/AppContextProvider";
import { Main } from './pages/Main';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppProvider>
          <ReactNotifications />
          <Main />
        </AppProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
